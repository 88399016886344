import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductCard.css';

const ProductCard = ({ product }) => {
  const navigate = useNavigate(); // Get the navigate function

  // Handle click to navigate to the product detail page
  const handleClick = () => {
    navigate(`/product/${product.id}`);
  };

  return (
    <div className="product-card" onClick={handleClick}>
      <img src={product.images[0]} alt={product.name} />
      <h5>{product.name}</h5>
    </div>
  );
};

export default ProductCard;
