import React from 'react';
import HomeCarousel from '../components/HomeCarousel';
import ProductPortfolio from '../components/ProductPortfolio';
import HeroBanner from '../components/HeroBanner';
import CompanyDetails from '../components/CompanyDetails';
import AboutPreview from '../components/AboutPreview';
import StatsWithAnimation from '../components/StatsWithAnimation';
const HomePage = () => (
  <div>
    <HomeCarousel />
    <CompanyDetails/>
    <h2 className="text-center mt-4">Our Products</h2>
    <ProductPortfolio />
    <AboutPreview/>
    {/* <img style={{width:"100%"}} src="/images/ab2.jpg" alt="About Us" /> */}
    <StatsWithAnimation/>
  </div>
);

export default HomePage;
