import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  const values = [
    {
      title: "Customer Centricity",
      description: "We prioritize understanding and addressing customer needs by providing tailored welding solutions for car and tractor safeguards, as well as custom-made seats for cars, buses, and vans.",
      icon: "/images/customer-centricity.jpg", // Replace with actual icon paths
    },
    {
      title: "Integrity",
      description: "Our business is built on trust and ethical practices, ensuring transparency and honesty in all we do, especially in the fabrication of durable car and tractor safeguards, custom seats for cars, buses, and vans.",
      icon: "/images/integrity.jpg",
    },
    {
      title: "Quality",
      description: "We deliver high-quality welding products, including robust safeguards for cars and tractors, and custom-designed seats for cars, buses, and vans, all engineered to last and perform under tough agricultural and industrial conditions.",
      icon: "/images/quality.jpg",
    },
    {
      title: "Innovation",
      description: "We stay ahead by embracing cutting-edge welding techniques to provide modern, durable safeguards for vehicles, tractors, and custom seats for cars, buses, and vans, enhancing safety and comfort for all users.",
      icon: "/images/innovation.jpg",
    },
    {
      title: "Sustainability",
      description: "Committed to eco-friendly practices, we ensure that our welding solutions for tractors, cars, buses, vans, and seats contribute to sustainable agriculture, transportation, and environmental preservation.",
      icon: "/images/sustainability.jpg",
    },
    {
      title: "Mission",
      description: "To create premium-quality, durable welding solutions, including safeguards for cars and tractors, and custom seats for cars, buses, and vans, empowering Pakistan's agricultural and transportation sectors with safety and reliability.",
      icon: "/images/mission.jpg",
    },
    {
      title: "Vision",
      description: "To be recognized as Pakistan’s foremost provider of welding solutions for vehicles, tractors, buses, vans, and seats, symbolizing quality, innovation, and trust in every product we deliver.",
      icon: "/images/vision.jpg",
    },
    {
      title: "Goal",
      description: "To support productivity and safety in agriculture and transportation by providing affordable and reliable safeguards for cars, tractors, buses, vans, and custom seats, all made with precision and durability.",
      icon: "/images/goal.jpg",
    },
  ];
  
  
  

  return (
    <div>
    <div className="about-page container py-5">
    <div className="about-row">
  {/* Left Column: Image */}
  <div className="about-image">
    <img src="/images/ab.jpg" alt="About Us" />
  </div>

  {/* Right Column: Text Content */}
  <div className="about-content">
    <section className="about-us-section" style={{ padding: '40px', backgroundColor: '#f9f9f9' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto', textAlign: 'center' }}>
        <div className="about-content">
          <h2 style={{ color: '#2c3e50', marginBottom: '20px' }}>About Us</h2>
          <p style={{ fontSize: '18px', color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
            Welcome to <strong>Rashid Safeguard and Seat Maker</strong>, Pakistan’s premier provider of welding solutions for agricultural and industrial needs. 
            We specialize in fabricating high-quality car safeguards, tractor safeguards, and durable seats for agricultural machinery. 
            Our goal is to protect your investments while enhancing the efficiency and safety of your machinery, contributing to the advancement of Pakistan’s agricultural sector.
          </p>
          <p style={{ fontSize: '18px', color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
            <strong>Our Mission:</strong> To provide durable, high-quality welding solutions for agriculture, ensuring safety and productivity for all users of our products. 
            We are committed to supporting Pakistan’s agricultural growth through innovation, craftsmanship, and reliability.
          </p>
          <p style={{ fontSize: '18px', color: '#555', lineHeight: '1.8', marginBottom: '40px' }}>
            <strong>Our Vision:</strong> To become Pakistan’s leading welding company for agricultural solutions, recognized for our expertise in safeguarding vehicles, 
            machinery, and creating safe, comfortable seats that ensure long-term success for our customers.
          </p>
        </div>

        <h3 style={{ color: '#2c3e50', margin: '30px 0 20px' }}>What Sets Us Apart</h3>
        <ul style={{ listStyle: 'none', padding: 0, textAlign: 'left', maxWidth: '800px', margin: '0 auto' }}>
          <li style={{ marginBottom: '15px', fontSize: '18px', lineHeight: '1.6', color: '#555' }}>
            <strong>Skilled Craftsmanship:</strong> Our experienced welders and engineers work tirelessly to create top-quality products, ensuring precision and safety.
          </li>
          <li style={{ marginBottom: '15px', fontSize: '18px', lineHeight: '1.6', color: '#555' }}>
            <strong>Durable Products:</strong> Whether it's a safeguard or a seat, our products are designed to endure the toughest agricultural environments, providing long-lasting value.
          </li>
          <li style={{ marginBottom: '15px', fontSize: '18px', lineHeight: '1.6', color: '#555' }}>
            <strong>Customer-First Approach:</strong> We listen to our customers' needs and deliver personalized solutions, ensuring satisfaction and peace of mind.
          </li>
          <li style={{ marginBottom: '15px', fontSize: '18px', lineHeight: '1.6', color: '#555' }}>
            <strong>Affordable Excellence:</strong> We offer high-quality products at competitive prices, making advanced agricultural solutions accessible to all.
          </li>
        </ul>
      </div>
    </section>
  </div>
</div>

      
      {/* Core Values Section */}
     
    </div>
    {/* <img className='ab2' src="/images/ab2.jpg" alt="About Us" /> */}
     <div className="core-values">
     <h3>Our Core Values</h3>
     <div className="values-grid">
       {values.map((value, index) => (
         <div className="value-card" key={index}>
           {/* <img src={value.icon} alt={value.title} className="value-icon" /> */}
           <h4>{value.title}</h4>
           <p>{value.description}</p>
         </div>
       ))}
     </div>
   </div>
   </div>
  );
};

export default AboutPage;
