import React from 'react';
import ProductCard from './ProductCard';
import './ProductPortfolio.css';

const ProductPortfolio = () => {
  const products = [
    {
      id: 1,
      name: 'Tractor Front Bodyguards',
      images: ['/images/1.png'],
      description: 'Durable and robust front bodyguards designed to protect your tractor from damage during agricultural operations, ensuring long-term safety and reliability.',
    },
    {
      id: 2,
      name: 'Agriculture Harrow Welding',
      images: ['/images/2.png'],
      description: 'Expertly welded harrow components for efficient soil preparation and improved seedbed quality, enhancing agricultural productivity.',
    },
    {
      id: 3,
      name: 'Tractor Roof Welding',
      images: ['/images/3.png'],
      description: 'Strong and weather-resistant tractor roof welding to provide optimal protection for operators during fieldwork, shielding them from sun and rain.',
    },
    {
      id: 4,
      name: 'Tractor Bumper Welding',
      images: ['/images/4.png'],
      description: 'Reinforced tractor bumpers for added durability and protection against impacts, ensuring the safety of your agricultural machinery.',
    },
    {
      id: 5,
      name: 'Soil Bedding Machine Making',
      images: ['/images/5.png'],
      description: 'Precision-crafted soil bedding machines designed for uniform bed preparation, improving crop planting and irrigation efficiency.',
    },
    {
      id: 6,
      name: 'Wagon Bumper Welding',
      images: ['/images/6.png'],
      description: 'Sturdy and reliable wagon bumpers welded to withstand heavy-duty usage, ensuring safety and longevity for transportation needs.',
    },
    {
      id: 7,
      name: 'Wagon Footrest Welding',
      images: ['/images/7.png'],
      description: 'Ergonomically designed and durable wagon footrests, welded to provide stable support and comfort for passengers and operators.',
    },
    {
      id: 8,
      name: 'Wagon Luggage Carrier Welding',
      images: ['/images/8.png'],
      description: 'High-quality welded luggage carriers for wagons, built to handle heavy loads securely, making transportation of goods easier and safer.',
    },
  ];
  
  
  
 
  return (
    <div className="product-portfolio">
      <h2>Products Portfolio</h2>
      <div className="product-grid">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductPortfolio;
