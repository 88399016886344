import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './HomeCarousel.css';

const HomeCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img src="/images/cover3.jpg" alt="Slide 1" style={{height:"80vh"}}/>
        <Carousel.Caption>
          <h3>Professional Agricultural Solutions</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="/images/cover2.jpg" alt="Slide 2" style={{height:"80vh"}} />
        <Carousel.Caption>
          <h3>Innovative Irrigation Systems</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeCarousel;
