import React from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import './ProductDetailPage.css';
import ProductPortfolio from '../components/ProductPortfolio';
const ProductDetailPage = () => {
  const { id } = useParams(); // Get the product id from the URL

  const productDetails = {
    1: { 
      name: 'Tractor Front Bodyguards', 
      description: 'Durable and robust front bodyguards designed to protect your tractor from damage during agricultural operations, ensuring long-term safety and reliability.', 
      images: ['/images/1.png', '/images/1.png'] 
    },
    2: { 
      name: 'Agriculture Harrow Welding', 
      description: 'Expertly welded harrow components for efficient soil preparation and improved seedbed quality, enhancing agricultural productivity.', 
      images: ['/images/2.png', '/images/2.png'] 
    },
    3: { 
      name: 'Tractor Roof Welding', 
      description: 'Strong and weather-resistant tractor roof welding to provide optimal protection for operators during fieldwork, shielding them from sun and rain.', 
      images: ['/images/3.png', '/images/3.png'] 
    },
    4: { 
      name: 'Tractor Bumper Welding', 
      description: 'Reinforced tractor bumpers for added durability and protection against impacts, ensuring the safety of your agricultural machinery.', 
      images: ['/images/4.png', '/images/4.png'] 
    },
    5: { 
      name: 'Soil Bedding Machine Making', 
      description: 'Precision-crafted soil bedding machines designed for uniform bed preparation, improving crop planting and irrigation efficiency.', 
      images: ['/images/5.png', '/images/5.png'] 
    },
    6: { 
      name: 'Wagon Bumper Welding', 
      description: 'Sturdy and reliable wagon bumpers welded to withstand heavy-duty usage, ensuring safety and longevity for transportation needs.', 
      images: ['/images/6.png', '/images/6.png'] 
    },
    7: { 
      name: 'Wagon Footrest Welding', 
      description: 'Ergonomically designed and durable wagon footrests, welded to provide stable support and comfort for passengers and operators.', 
      images: ['/images/7.png', '/images/7.png'] 
    },
    8: { 
      name: 'Wagon Luggage Carrier Welding', 
      description: 'High-quality welded luggage carriers for wagons, built to handle heavy loads securely, making transportation of goods easier and safer.', 
      images: ['/images/8.png', '/images/8.png'] 
    },
  };
  
  
  

  const product = productDetails[id]; // Assign product details based on the 'id'

  if (!product) {
    return (
      <div className="product-detail">
        <p>Product not found!</p>
      </div>
    );
  }

  return (
    <div>
    <div className="product-detail">
      <div className="product-carousel">
        <Carousel>
          {product.images.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100 product-image"
                src={image}
                alt={product.name}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="product-info">
        <h1>{product.name}</h1>
        <p>{product.description}</p>
      </div>
     
    </div>
    <ProductPortfolio />
    </div>
    
  );
};

export default ProductDetailPage;
