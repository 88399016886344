import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import './StatsWithAnimation.css';

const StatsWithAnimation = () => {
  const stats = [
    { value: 8, label: 'Products', suffix: '+', graphColor: '#4CAF50' },
    { value: 45, label: 'Employees', suffix: '+', graphColor: '#2196F3' },
    { value: 50, label: 'Customers', suffix: 'k', graphColor: '#FFC107' },
    { value: 17, label: 'Registered Since', suffix: '+', graphColor: '#FFC107' },
  ];

  const [cycle, setCycle] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCycle((prevCycle) => prevCycle + 1); // Increment cycle to re-trigger animation
    }, 4000); // 2 seconds animation + 2 seconds delay
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <section className="stats-section">
      <h2 className="stats-title">Our Achievements</h2>
      <div className="stats-container">
        {stats.map((stat, index) => (
          <div key={index} className="stat-card">
            <div className="stat-number">
              {/* The key prop ensures the CountUp component resets */}
              <CountUp
                key={`${stat.label}-${cycle}`}
                start={0}
                end={stat.value}
                duration={2}
                suffix={stat.suffix}
              />
            </div>
            <p className="stat-label">{stat.label}</p>
            <div
              className="graph-bar"
              style={{
                backgroundColor: stat.graphColor,
                animation: `grow 2s ease-in-out`,
              }}
            ></div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StatsWithAnimation;
