import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const SimpleCarousel = () => {
  const images = [
    { id: 1, src: '/agri-machinery-website/public/jed-owen-ajZibDGpPew-unsplash.jpg', alt: 'Product Image 1' },
    
  ];

  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      showIndicators={true}
      infiniteLoop={true}
      autoPlay={true}
      interval={3000}
      stopOnHover={true}
    >
      {images.map((image) => (
        <div key={image.id}>
          <img src={image.src} alt={image.alt} />
        </div>
      ))}
    </Carousel>
  );
};

export default SimpleCarousel;
