import React, { useState } from 'react';
import './CompanyDetails.css'; // You can add styling here

const CompanyDetails = () => {
  const [isExpanded, setIsExpanded] = useState(false); // State to toggle between short and long text

  const handleToggle = () => {
    setIsExpanded(!isExpanded); // Toggle the state
  };

  return (
    <div className="company-details">
      <h1>Welcome to RASHID SAFEGUARD and SEAT MAKER: Strength in Every Weld</h1>
      <p>
        At <strong>RASHID SAFEGUARD and SEAT MAKER</strong>, we take pride in being a key player in Pakistan’s industrial and agricultural sectors. Our expertly crafted welding solutions include the production of high-quality car, van, and bus seats, as well as durable safeguards for cars and tractors.
      </p>
      <p>
        Whether it’s providing comfort and durability in vehicle seats or ensuring the safety of your agricultural machinery and vehicles, our products are built with precision and care to meet your specific needs.
      </p>
      
      {isExpanded && (
        <div className="expanded-details">
          <h3>Explore Our Offerings:</h3>
          <ul>
            <li><strong>Car, Van, and Bus Seats:</strong> We weld strong and comfortable seats for cars, vans, and buses, designed for both durability and comfort.</li>
            <li><strong>Car Safeguards:</strong> Protect your vehicles with high-quality and reliable safeguards that offer protection during transit and use.</li>
            <li><strong>Tractor Safeguards:</strong> Ensure your agricultural machinery is tough enough to withstand the demands of fieldwork with our tractor safeguards.</li>
            <li><strong>Custom Welding Solutions:</strong> We also provide custom welding for specific needs and tailored solutions for your projects.</li>
          </ul>

          <h3>Why Choose RASHID SAFEGUARD and SEAT MAKER?</h3>
          <ul>
            <li><strong>Superior Quality Products:</strong> We craft each product with the highest quality materials and advanced techniques.</li>
            <li><strong>Reliable Performance:</strong> Designed for performance and durability in the toughest environments, including agricultural fields and on the road.</li>
            <li><strong>Affordable Solutions:</strong> We offer high-quality products at competitive prices to cater to various budgets.</li>
          </ul>

          <p>
            Join us in shaping the future of agriculture, transportation, and comfort in Pakistan. Together, we’ll safeguard success and provide lasting solutions.
          </p>
        </div>
      )}

      <button className="toggle-button" onClick={handleToggle}>
        {isExpanded ? 'Show Less' : 'Show All'}
      </button>
    </div>
  );
};

export default CompanyDetails;
