import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MainNavbar = () => (
  <Navbar bg="success" variant="dark" expand="lg">
    <Container>
      {/* Logo on the left side */}
      <Navbar.Brand as={Link} to="/">
        <img src="/images/logo2.png" className="logo" style={{width:"150px", height:"100px"}}/>
      </Navbar.Brand>

      {/* Navbar toggle for mobile view */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      
      {/* Navbar collapse with nav items aligned to the right */}
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto"> {/* Add ml-auto to push nav items to the right */}
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/about">About Us</Nav.Link>
          <Nav.Link as={Link} to="/products">Products</Nav.Link>
          <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default MainNavbar;
