// ProductPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './ProductPage.css';

const ProductPage = () => {
  // Sample product data
  const products = [
    { 
      id: 1, 
      name: 'Tractor Front Bodyguards', 
      description: 'Durable and robust front bodyguards designed to protect your tractor from damage during agricultural operations, ensuring long-term safety and reliability.', 
      image: '/images/1.png' 
    },
    { 
      id: 2, 
      name: 'Agriculture Harrow Welding', 
      description: 'Expertly welded harrow components for efficient soil preparation and improved seedbed quality, enhancing agricultural productivity.', 
      image: '/images/2.png' 
    },
    { 
      id: 3, 
      name: 'Tractor Roof Welding', 
      description: 'Strong and weather-resistant tractor roof welding to provide optimal protection for operators during fieldwork, shielding them from sun and rain.', 
      image: '/images/3.png' 
    },
    { 
      id: 4, 
      name: 'Tractor Bumper Welding', 
      description: 'Reinforced tractor bumpers for added durability and protection against impacts, ensuring the safety of your agricultural machinery.', 
      image: '/images/4.png' 
    },
    { 
      id: 5, 
      name: 'Soil Bedding Machine Making', 
      description: 'Precision-crafted soil bedding machines designed for uniform bed preparation, improving crop planting and irrigation efficiency.', 
      image: '/images/5.png' 
    },
    { 
      id: 6, 
      name: 'Wagon Bumper Welding', 
      description: 'Sturdy and reliable wagon bumpers welded to withstand heavy-duty usage, ensuring safety and longevity for transportation needs.', 
      image: '/images/6.png' 
    },
    { 
      id: 7, 
      name: 'Wagon Footrest Welding', 
      description: 'Ergonomically designed and durable wagon footrests, welded to provide stable support and comfort for passengers and operators.', 
      image: '/images/7.png' 
    },
    { 
      id: 8, 
      name: 'Wagon Luggage Carrier Welding', 
      description: 'High-quality welded luggage carriers for wagons, built to handle heavy loads securely, making transportation of goods easier and safer.', 
      image: '/images/8.png' 
    },
  ];
  
  
  

  return (
    <div className="product-page">
      <h2 className="page-title">Our Products</h2>
      <div className="product-grid">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} className="product-image" />
            <h4 className="product-name">{product.name}</h4>
            <p className="product-description">{product.description}</p>
            <Link to={`/product/${product.id}`} className="view-details">
              View Details
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
