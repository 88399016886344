import React from 'react';
import { Link } from 'react-router-dom'; // Ensure you import Link
import './Footer.css';

const Footer = () => (
  <footer className="footer bg-success text-white py-4">
    <div className="container">
      <div className="row">
        {/* Section 1: Logo & Description */}
        <div className="col-md-3">
          <div className="footer-logo">
            <img src="/images/logo2.png" alt="Logo" className="logo" />
          </div>
          <p className="footer-description">
            We are a leading company providing quality products for agricultural solutions.
          </p>
        </div>

        {/* Section 2: Address and Contact */}
        <div className="col-md-3">
  <h5>Contact Us</h5>
  
  {/* Address with SVG Icon */}
  <div className="contact-info d-flex align-items-center mb-3">
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-geo-alt-fill me-2" viewBox="0 0 16 16">
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
    </svg>
    <p className="mb-0">OPP GUJARNAWALA TRACTOR HOUSE, GUJAR KHADAN, MULTAN, CANTT, MULTAN</p>
  </div>

  {/* Phone with SVG Icon */}
  <div className="contact-info d-flex align-items-center mb-3">
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-phone me-2" viewBox="0 0 16 16">
      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
    </svg>
    <p className="mb-0">Phone: 0306-7395886, 0300-8385886</p>
  </div>

  {/* Email with SVG Icon */}
  <div className="contact-info d-flex align-items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope me-2" viewBox="0 0 16 16">
      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
    </svg>
    <p className="mb-0">Email: rashali8385886@gmail.com</p>
  </div>
</div>



        {/* Section 3: Product List with Links */}
        <div className="col-md-3">
          <h5>Our Products</h5>
          <ul className="product-list">
            <li><Link to="/product/1">Super Seeder</Link></li>
            <li><Link to="/product/2">Wheat Straw Reaper</Link></li>
            <li><Link to="/product/3">Laser Land Leveler</Link></li>
          </ul>
        </div>

        {/* Section 4: Map */}
        <div className="col-md-3">
          <h5>Find Us</h5>
          <div className="map rounded">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13794.88312416242!2d71.42864742443459!3d30.187969108302966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b31622d3f6e3b%3A0x986cc7096d66918d!2sRASHID%20SAFE%20GUARD%20%26%20SEAT%20MAKER!5e0!3m2!1sen!2suk!4v1731708570544!5m2!1sen!2suk"
              width="100%"
              height="150"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    {/* Footer bottom */}
    <div className="footer-bottom text-center mt-4">
      <p>&copy; 2024 RASHID SAFEGUARD AND SEAT MAKER. </p>
      <p>All rights reserved.</p>
      <p>Contact us: rashali8385886@gmail.com</p>
    </div>
  </footer>
);

export default Footer;
