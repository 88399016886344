import React from 'react';
import './AboutPreview.css';

const AboutPreview = () => (
  <section className="about-preview">
    <h2>About Us</h2>
    <p className="intro-text">
      At <strong>RASHID SAFEGUARD and SEAT MAKER</strong>, we are committed to providing high-quality welding solutions for the automotive and agricultural sectors in Pakistan. Our expertise lies in crafting durable seats for cars, vans, and buses, as well as reliable safeguards for cars and tractors.
    </p>
    
    <div className="preview-cards">
      
      {/* Our Mission */}
      <div className="preview-card">
        <img src="/images/mission.jpg" alt="Our Mission" className="preview-image" />
        <h3>Our Mission</h3>
        <p>
          To provide innovative and durable welding solutions, ensuring safety, comfort, and performance for vehicles and agricultural machinery.
        </p>
      </div>

      {/* Our Vision */}
      <div className="preview-card">
        <img src="/images/vission2.jpg" alt="Our Vision" className="preview-image" />
        <h3>Our Vision</h3>
        <p>
          To be Pakistan’s leading provider of welding solutions for vehicle seats, agricultural machinery, and transportation safeguards.
        </p>
      </div>

      {/* Innovation */}
      <div className="preview-card">
        <img src="/images/inn.jpg" alt="Innovation" className="preview-image" />
        <h3>Innovation</h3>
        <p>
          Continuously evolving our welding techniques to create reliable, eco-friendly, and efficient solutions for the automotive and agricultural industries.
        </p>
      </div>
    </div>

    {/* Link to Full About Page */}
    <div className="about-link">
      <a href="/about">Learn more about us</a>
    </div>
  </section>
);

export default AboutPreview;
